<script setup>
import {onBeforeMount, ref} from 'vue'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const {$listen} = useNuxtApp()

const visibleResources = ref([])
const selectedLocation = ref('')

visibleResources.value = props.content.jobs_list

const filterLocations = []

function arraySort(array) {
  array.sort((a, b) => {
    if (a.value < b.value) {
      return -1
    }
    if (a.value > b.value) {
      return 1
    }
    return 0
  })
}

onBeforeMount(() => {
  arraySort(filterLocations)
})

onMounted(() => {
  $listen('change:selectValue', async (element) => {
    if (element.className == 'filter-location') {
      selectedLocation.value = element.optionValue
      // console.log('Selection:', selectedLocation.value)
      await nextTick()

      raiseCustomEvent()
    }
  })
})

function filterByLocation(resources) {
  if (selectedLocation.value === '') return resources
  return resources.filter(
    (resource) => resource.location === selectedLocation.value
  )
}

// Computed property to filter resources based on selected category
const filteredResources = computed(() => {
  return selectedLocation.value === ''
    ? visibleResources.value
    : filterByLocation(visibleResources.value)
})

const raiseCustomEvent = () => {
  // Dispatch the custom event on the window object
  // setTimeout(() => {
  const updateAnimationsEvent = new CustomEvent('updateAnimationsEvent', {})
  window.dispatchEvent(updateAnimationsEvent)
  // }, 500)
}

// Function to manage filters selects reset
const locationRef = ref(null)

const resetFilterSelects = async () => {
  locationRef.value.resetSelect()
  
  await nextTick()
  raiseCustomEvent()
}
</script>

<template>
  <section class="section section--open-positions">
    <div class="section-wrapper">
      <h2 class="section-title">{{ $t('opening_positions') }}</h2>
      <div class="section-filters">
        <div class="filter">
          <SharedBasicCustomSelect
            ref="categoryRef"
            :options="content.locations_list"
            allLabel="All locations"
            :addClass="'filter-location'"
            name="location" />
        </div>
      </div>
      <div class="section-jobs">
        <div class="section-jobs-list">
          <div v-for="(job, index) in filteredResources" :key="index">
            <NuxtLink
              v-if="job.origin === 'cms'"
              :to="localePath(`/careers/${job.link}`)"
              class="job-item sensible">
              <h4 class="job-item-title">
                {{ job.title }}
                <span
                  class="job-item-new job-item-new--desktop"
                  v-if="job.new"
                  >{{ $t('new') }}</span
                >
              </h4>
              <div class="job-item-location">
                {{ job.location }}
                {{ job.remote ? '- ' + $t('remote_position') : '' }}
              </div>
              <div class="job-item-cta">
                <span
                  class="job-item-new job-item-new--mobile"
                  v-if="job.new"
                  >{{ $t('new') }}</span
                >
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
            </NuxtLink>
            <NuxtLink
              v-else-if="job.origin === 'altamira'"
              :to="job.link"
              class="job-item sensible"
              target="_blank"
              rel="noopener noreferrer">
              <h4 class="job-item-title">
                {{ job.title }}
                <span
                  class="job-item-new job-item-new--desktop"
                  v-if="job.new"
                  >{{ $t('new') }}</span
                >
              </h4>
              <div class="job-item-location">
                {{ job.location }}
                {{ job.remote ? '- remote' : '' }}
              </div>
              <div class="job-item-cta">
                <span
                  class="job-item-new job-item-new--mobile"
                  v-if="job.new"
                  >{{ $t('new') }}</span
                >
                <div class="btn btn--round btn--round-dark">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-arrow"></use>
                  </svg>
                </div>
              </div>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section--open-positions {
  margin-bottom: pxrem(50);
  margin-top: pxrem(50);

  @include mq(md) {
    margin-bottom: pxrem(120);
    margin-top: pxrem(120);
  }

  .section-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .section-title {
    @include display-1;
    font-weight: 400;
    margin-bottom: pxrem(50);
    text-align: center;

    @include mq(md) {
      margin-bottom: pxrem(100);
    }
  }

  .section-filters {
    margin-bottom: pxrem(50);

    @include mq(md) {
      display: flex;
      justify-content: center;
      margin-bottom: pxrem(100);
    }
  }

  .filter {
    margin-bottom: pxrem(10);

    @include mq(md) {
      margin-bottom: 0;
      margin-left: pxrem(10);
      margin-right: pxrem(10);
      width: col-width(8);
    }

    @include mq(xl) {
      width: col-width(6);
    }
  }

  .section-jobs {
  }

  .section-jobs-title {
    @include label;
    font-weight: 400;
    margin-bottom: prem(35);
  }

  .section-jobs-list {
    margin-bottom: pxrem(40);

    @include mq(md) {
      margin-bottom: pxrem(100);
    }
  }

  .job-item {
    border-bottom: 1px solid $color-gray;
    display: block;
    padding-bottom: pxrem(22);
    padding-top: pxrem(22);
    text-decoration: none;
    width: 100%;

    @include mq(lg) {
      align-items: center;
      display: flex;
      padding-bottom: pxrem(30);
      padding-top: pxrem(30);
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      .btn.btn--round-dark {
        background-color: $color-blue;
        border-color: $color-blue;
        color: $color-white;

        svg {
          @include icon-color($color: $color-white);
        }
      }
    }
  }

  .job-item-title {
    @include display-3;
    align-items: center;
    display: flex;
    font-weight: 400;
    margin-bottom: pxrem(8);

    @include mq(lg) {
      margin-bottom: 0;
      padding-right: pxrem(4);
      width: col-width(14);
    }
  }

  .job-item-new {
    @include label($color-white);
    background-color: $color-blue;
    border-radius: 25px;
    display: inline-block;
    padding: pxrem(5) pxrem(15);

    &.job-item-new--desktop {
      display: none;

      @include mq(lg) {
        display: block;
        margin-left: pxrem(10);
        padding: pxrem(7) pxrem(18);
      }
    }

    &.job-item-new--mobile {
      @include mq(lg) {
        display: none;
      }
    }
  }

  .job-item-location {
    @include paragraph($color-gray-dark);
    margin-bottom: pxrem(16);

    @include mq(lg) {
      margin-bottom: 0;
      padding-right: pxrem(4);
      width: col-width(6);
    }
  }

  .job-item-cta {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include mq(lg) {
      display: block;
      text-align: right;
      width: col-width(2);
    }
  }
}
</style>
